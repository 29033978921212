// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { colors, boxShadow, borderRadius } from '@styles/theme';
import css from '@emotion/css';

export interface CardProps {
    children: ReactNode;
    disabled?: boolean;
    hover?: boolean;
}

const disabledStyle = css`
    box-shadow: ${boxShadow('none')};
`;

const hoverStyle = css`
    :hover {
        box-shadow: ${boxShadow('lg')};
        transform: scale(1.005);
    }
`;

const Container = styled.div<CardProps>`
    display: flex;
    flex-direction: column;
    height: 100%;

    overflow: hidden;

    background-color: ${colors('white')};
    border-radius: ${borderRadius('md')};
    box-shadow: ${boxShadow('md')};

    transition: all ease 100ms;

    ${props => props.hover && hoverStyle}

    ${props => props.disabled && disabledStyle}
`;

export const Card: React.FC<CardProps> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};
