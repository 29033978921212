// Modules
import React, { ReactNode, RefObject, useState, useEffect } from 'react';
import 'react-toggle/style.css';
// Styles
import {
    ThemeProvider,
    theme,
    colors,
    spacing,
    fontFamily,
    fontSize,
    fontWeight,
    borderRadius,
    boxShadow,
    screens,
} from '@styles/theme';
import { Global, css } from '@emotion/core';
import normalize from 'emotion-normalize';
import { global } from '@styles/global';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faJs, faReact, faNodeJs, faPatreon, faGithub, faTwitter, faGitAlt } from '@fortawesome/free-brands-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import {
    faClock,
    faHeart,
    faStepForward,
    faArrowLeft,
    faArrowRight,
    faUserGraduate,
    faUserAstronaut,
    faUserNinja,
    faUserSecret,
    faUserAlien,
    faUserCowboy,
    faAlicorn,
    faCatSpace,
    faUfoBeam,
    faLock,
    faRocket,
    faCheck,
    faSolarSystem,
} from '@fortawesome/pro-duotone-svg-icons';
import { Outer } from '../outer';
import { Inner, Typo } from '..';
import styled from '@emotion/styled';
import { Card } from '../card/Card';
import { CardContent, CardBody } from '../card';
import { Icon } from '@components/core/icon';
import UserService from '@services/UserService';
import { ToastContainer, Slide } from 'react-toastify';
import { Link } from 'gatsby';

library.add(
    faJs,
    faReact,
    faNodeJs,
    faPatreon,
    faGithub,
    faTwitter,
    faGitAlt,
    faClock,
    faHeart,
    faPlay,
    faStepForward,
    faCheck,
    faArrowLeft,
    faArrowRight,
    faUserGraduate,
    faUserAstronaut,
    faUserNinja,
    faUserSecret,
    faUserAlien,
    faUserCowboy,
    faAlicorn,
    faCatSpace,
    faUfoBeam,
    faLock,
    faRocket,
    faSolarSystem,
);

interface LayoutProps {
    children: ReactNode;
}

const h3 = css`
    margin-bottom: ${spacing(2)};

    font-weight: ${fontWeight('medium')};
    font-size: ${fontSize('lg')};
    font-family: ${fontFamily('sans')};
`;

const h5 = css`
    font-weight: ${fontWeight('normal')};
    font-size: ${fontSize('sm')};
    font-family: ${fontFamily('sans')};
`;

const input = css`
    background: none;
    border: none;

    &:focus,
    &:placeholder-shown {
        border-bottom: 1px solid ${colors('gray', 400)};
    }

    @media (max-width: ${screens('sm')}) {
        font-size: ${fontSize('xl')};
    }
`;

const StyledInputH4 = styled.input`
    ${h5}
    ${input}
`;

const InnerCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${spacing(12)};
    height: ${spacing(12)};
    margin-right: ${spacing(4)};

    color: ${colors('gray', 100)};
    font-size: ${fontSize('xl')};

    background: #bd93f9;
    border-radius: ${borderRadius('md')};
`;

interface Race {
    name: string;
    icon: string;
    color: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const name = UserService.getUserName();
        const email = UserService.getUserEmail();

        setName(name);
        setEmail(email);
    }, []);

    const handleSubmit = (event?): void => {
        event?.preventDefault();
        UserService.editUserName(name);
        UserService.editUserEmail(email);
        setIsEditing(false);
    };

    const handleNameChange = (event): void => {
        const name = event.target.value;
        setName(name);
        setIsEditing(true);
    };

    const handleEmailChange = (event): void => {
        const email = event.target.value;
        setEmail(email);
        setIsEditing(true);
    };

    const [open, setOpen] = useState(false);

    return (
        <ThemeProvider theme={theme} {...props}>
            <Global
                styles={css`
                    ${normalize}
                    ${global}
                `}
            />
            <div
                css={css`
                    position: absolute;
                    background-color: black;
                    height: 100vh;
                    width: 100vw;
                    opacity: ${open ? 0.8 : 0};
                    transition: all ease 400ms;

                    z-index: ${open ? 500 : -1};
                `}
            ></div>
            <ToastContainer
                css={css`
                    > div {
                        padding: ${spacing(3)};

                        border-left: ${spacing(1)} solid ${colors('green', 400)};
                        border-radius: ${borderRadius('md')};
                        box-shadow: ${boxShadow('md')};
                    }
                `}
                position="bottom-right"
                transition={Slide}
                hideProgressBar={true}
                closeButton={false}
                autoClose={2500}
            />
            <Outer>
                <div
                    css={css`
                        background-color: ${colors('white')};
                        width: 100%;
                    `}
                >
                    <Inner
                        css={css`
                            position: relative;
                            padding-top: ${spacing(4)} !important;
                            padding-bottom: ${spacing(4)} !important;
                            height: 80px;
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <span
                            css={css`
                                position: absolute;
                                left: 2rem;
                                top: 50%;
                                transform: translateY(-50%);
                            `}
                        >
                            <Link to="/">
                                <Typo
                                    variant="menu"
                                    css={css`
                                        font-size: ${fontSize('xxl')};
                                    `}
                                >
                                    unspace
                                </Typo>
                            </Link>
                        </span>
                        <InnerCircle
                            onClick={() => {
                                setOpen(open => !open);
                            }}
                            css={css`
                                cursor: pointer;
                                position: absolute;
                                opacity: ${open ? 0 : 1};
                                transition: all ease 150ms;
                                z-index: ${open ? -2 : 600};
                                background-color: ${colors('white')};
                                width: ${spacing(12)};
                                height: ${spacing(12)};
                                font-size: ${fontSize('xl')};
                                margin: 0;
                                color: ${colors('secondary')};
                                border: ${open ? `1px ${colors('gray', 200)} solid` : 0};

                                &:hover {
                                    background-color: white;
                                    color: ${colors('gray', 800)};
                                    border: ${open ? `1px ${colors('gray', 200)} solid` : 0};
                                    box-shadow: ${open ? 'none' : boxShadow('md')};
                                }
                            `}
                        >
                            <Icon id="astronaut"></Icon>
                        </InnerCircle>

                        <Card
                            css={css`
                                z-index: ${open ? 600 : -1};
                                height: auto;
                                transition: all ease 150ms;

                                position: absolute;
                                opacity: ${open ? 1 : 0};
                                transition: all ease 150ms;

                                @media (max-width: ${screens('sm')}) {
                                    position: fixed;
                                    top: 0;
                                    left: 0;
                                    width: 100vw;
                                    height: 100vh;
                                    display: flex;
                                    border-radius: 0;
                                }
                            `}
                        >
                            <CardContent>
                                <CardBody
                                    css={css`
                                        @media (max-width: ${screens('sm')}) {
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate3d(-50%, -50%, 0);

                                            button {
                                                margin-top: ${spacing(8)};
                                                padding: ${spacing(4)};
                                            }
                                        }
                                    `}
                                >
                                    <form
                                        onSubmit={handleSubmit}
                                        css={css`
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: center;
                                                & > input + label {
                                                    margin-top: ${spacing(6)};
                                                }
                                            `}
                                        >
                                            <Typo
                                                variant="label"
                                                css={css`
                                                    margin-bottom: ${spacing(1)};
                                                    color: ${colors('gray', 600)};
                                                    font-weight: ${fontWeight('normal')};
                                                `}
                                            >
                                                Ton nom
                                            </Typo>

                                            <StyledInputH4
                                                name="name"
                                                placeholder="Voyageur"
                                                onChange={handleNameChange}
                                                value={name}
                                            ></StyledInputH4>
                                            <Typo
                                                variant="label"
                                                css={css`
                                                    margin-bottom: ${spacing(1)};
                                                    color: ${colors('gray', 600)};
                                                    font-weight: ${fontWeight('normal')};
                                                `}
                                            >
                                                Ton email
                                            </Typo>

                                            <StyledInputH4
                                                name="email"
                                                placeholder="voyageur@unspace.io"
                                                onChange={handleEmailChange}
                                                value={email}
                                                type="email"
                                            ></StyledInputH4>
                                            <div
                                                css={css`
                                                    margin-top: ${spacing(6)};
                                                `}
                                            >
                                                <Typo onClick={() => setOpen(false)} type="submit" variant="button">
                                                    <Icon id="check"></Icon>
                                                </Typo>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </CardContent>
                        </Card>
                    </Inner>
                </div>

                <div
                    css={css`
                        background-color: ${colors('white')};
                        width: 100%;
                        border-bottom: 1px solid ${colors('gray', 300)};
                    `}
                >
                    <Inner
                        css={css`
                            padding-top: 0 !important;
                            padding-bottom: 10px !important;
                            word-break: keep-all;
                            white-space: nowrap;
                        `}
                    >
                        <Typo
                            css={css`
                                font-size: ${fontSize('sm')};
                                border-bottom: 2px solid black;
                                padding-bottom: 10px !important;
                                margin-right: ${spacing(12)};
                                padding-top: 0 !important;
                            `}
                        >
                            Expéditions
                        </Typo>
                        <Typo
                            css={css`
                                font-size: ${fontSize('sm')};
                                border-bottom: 2px solid transparent;
                                padding-bottom: 10px !important;
                                margin-right: ${spacing(12)};
                                padding-top: 0 !important;
                                color: ${colors('gray', 400)};
                                cursor: not-allowed;
                            `}
                        >
                            <Icon id="lock"></Icon> Journal de bord
                        </Typo>
                    </Inner>
                </div>
            </Outer>
            {children}
        </ThemeProvider>
    );
};
