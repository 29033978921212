// Components
import React, { ReactNode, useState, useLayoutEffect } from 'react';
import { Collapse as BaseCollapse } from 'react-collapse';

import { css } from '@emotion/core';
import { colors, spacing } from '@styles/theme';
import { Title } from '../title';

export interface CollapseProps {
    children: ReactNode;
    type: 'hint' | 'solution';
}

export const Collapse: React.FC<CollapseProps> = ({ children, type, ...props }) => {
    const [open, setOpen] = useState(false);

    useLayoutEffect(() => {
        if (open) {
            var element = document.querySelector(`#${type}`);
            element && element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [open]);

    return (
        <div
            {...props}
            css={css`
                padding-top: ${spacing(8)};
                & > a {
                    color: ${colors('gray', 900)} !important;
                }
            `}
        >
            {!open && (
                <div onClick={() => setOpen(true)}>
                    {type === 'hint' && (
                        <Title
                            id="secret"
                            css={css`
                                :hover {
                                    cursor: pointer;
                                    color: ${colors('gray', 700)};
                                }
                            `}
                        >
                            Donne moi un indice
                        </Title>
                    )}
                    {type === 'solution' && (
                        <Title
                            id="cat"
                            css={css`
                                :hover {
                                    cursor: pointer;
                                    color: ${colors('gray', 700)};
                                }
                            `}
                        >
                            Donne moi la solution
                        </Title>
                    )}
                </div>
            )}
            <BaseCollapse isOpened={open}>
                <div id={type}>{children}</div>
            </BaseCollapse>
        </div>
    );
};
