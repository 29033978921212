import styled from '@emotion/styled';
import { screens } from '@styles/theme';

export const Center = styled.div`
    @media (min-width: ${screens('lg')}) {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100vh - 64px;
    }
`;
