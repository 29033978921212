// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { colors } from '@styles/theme';

export interface CardContentProps {
    children: ReactNode;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    color: ${colors('gray', 800)};
`;

export const CardContent: React.FC<CardContentProps> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};
