import React from 'react';
import { BarsIcon } from './bars/BarsIcon';
import { ClockIcon } from './clock/ClockIcon';
import { GitHubIcon } from './github/GitHubIcon';
import { HeartIcon } from './heart/HeartIcon';
import { JsIcon } from './js/JsIcon';
import { NodeJsIcon } from './node-js/NodeJsIcon';
import { ReactIcon } from './react/ReactIcon';
import { TimesIcon } from './times/TimesIcon';
import { TsIcon } from './ts/TsIcon';
import { TwitterIcon } from './twitter/TwitterIcon';
import { PlayIcon } from './play/PlayIcon';
import { StepForwardIcon } from './step-forward/StepForwardIcon';
import { GitIcon } from './git/GitIcon';
import { CheckIcon } from './check/CheckIcon';
import { ArrowLeftIcon } from './arrow-left/ArrowLeftIcon';
import { ArrowRightIcon } from './arrow-right/ArrowRightIcon';
import { GraduateIcon } from './graduate/GraduateIcon';
import { AstronautIcon } from './astronaut/AstronautIcon';
import { NinjaIcon } from './ninja/NinjaIcon';
import { SecretIcon } from './secret/SecretIcon';
import { AlienIcon } from './alien/AlienIcon';
import { CowboyIcon } from './cowboy/CowboyIcon';
import { LicornIcon } from './licorn/LicornIcon';
import { CatIcon } from './cat/CatIcon';
import { UfoIcon } from './ufo/UfoIcon';
import { LockIcon } from './lock/LockIcon';
import { RocketIcon } from './rocket/RocketIcon';
import { SolarSystemeIcon } from './solar-system/SolarSystemIcon';

export type IconId =
    | 'bars'
    | 'clock'
    | 'github'
    | 'heart'
    | 'js'
    | 'node-js'
    | 'react'
    | 'times'
    | 'ts'
    | 'twitter'
    | 'play'
    | 'step-forward'
    | 'git'
    | 'check'
    | 'arrow-left'
    | 'arrow-right'
    | 'graduate'
    | 'astronaut'
    | 'ninja'
    | 'secret'
    | 'alien'
    | 'cowboy'
    | 'licorn'
    | 'cat'
    | 'ufo'
    | 'lock'
    | 'rocket';

export interface IconProps {
    id: IconId;
    colored?: boolean;
}

export const Icon: React.FC<IconProps> = props => {
    const { id } = props;

    if (id === 'bars') {
        return <BarsIcon {...props} />;
    }
    if (id === 'clock') {
        return <ClockIcon {...props} />;
    }
    if (id === 'github') {
        return <GitHubIcon {...props} />;
    }
    if (id === 'heart') {
        return <HeartIcon {...props} />;
    }
    if (id === 'js') {
        return <JsIcon {...props} />;
    }
    if (id === 'node-js') {
        return <NodeJsIcon {...props} />;
    }
    if (id === 'react') {
        return <ReactIcon {...props} />;
    }
    if (id === 'times') {
        return <TimesIcon {...props} />;
    }
    if (id === 'ts') {
        return <TsIcon {...props} />;
    }
    if (id === 'twitter') {
        return <TwitterIcon {...props} />;
    }
    if (id === 'play') {
        return <PlayIcon {...props} />;
    }
    if (id === 'step-forward') {
        return <StepForwardIcon {...props} />;
    }
    if (id === 'git') {
        return <GitIcon {...props} />;
    }
    if (id === 'check') {
        return <CheckIcon {...props} />;
    }

    if (id === 'arrow-left') {
        return <ArrowLeftIcon {...props} />;
    }

    if (id === 'arrow-right') {
        return <ArrowRightIcon {...props} />;
    }

    if (id === 'graduate') {
        return <GraduateIcon {...props} />;
    }

    if (id === 'astronaut') {
        return <AstronautIcon {...props} />;
    }

    if (id === 'ninja') {
        return <NinjaIcon {...props} />;
    }

    if (id === 'secret') {
        return <SecretIcon {...props} />;
    }

    if (id === 'alien') {
        return <AlienIcon {...props} />;
    }

    if (id === 'cowboy') {
        return <CowboyIcon {...props} />;
    }

    if (id === 'licorn') {
        return <LicornIcon {...props} />;
    }

    if (id === 'cat') {
        return <CatIcon {...props} />;
    }

    if (id === 'ufo') {
        return <UfoIcon {...props} />;
    }

    if (id === 'lock') {
        return <LockIcon {...props} />;
    }

    if (id === 'rocket') {
        return <RocketIcon {...props} />;
    }

    if (id === 'solar-system') {
        return <SolarSystemeIcon {...props} />;
    }

    return null;
};
