import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { spacing, Spacing, screens } from '@styles/theme';

export interface GridProps {
    children: ReactNode;
    sm?: GridConfig;
    md?: GridConfig;
    lg?: GridConfig;
    xl?: GridConfig;
}
export interface GridConfig {
    columns: number;
    columnGap: Spacing;
    rowGap: Spacing;
}

const Container = styled.div<GridProps>`
    position: relative;

    display: grid;

    @media (min-width: ${screens('lg')}) {
        grid-row-gap: ${props => props.lg && spacing(props.lg.rowGap)};
        grid-column-gap: ${props => props.lg && spacing(props.lg.columnGap)};
        grid-template-rows: 1fr;
        grid-template-columns: ${props => props.lg && `repeat(${props.lg.columns}, 1fr)`};
    }

    @media (max-width: ${screens('lg')}) {
        grid-row-gap: ${props => props.md && spacing(props.md.rowGap)};
        grid-column-gap: ${props => props.md && spacing(props.md.columnGap)};
        grid-template-rows: 1fr;
        grid-template-columns: ${props => props.md && `repeat(${props.md.columns}, 1fr)`};
    }

    @media (max-width: ${screens('sm')}) {
        grid-row-gap: ${props => props.sm && spacing(props.sm.rowGap)};
        grid-column-gap: ${props => props.sm && spacing(props.sm.columnGap)};
        grid-template-rows: 1fr;
        grid-template-columns: ${props => props.sm && `repeat(${props.sm.columns}, 1fr)`};
    }
`;

export const Grid: React.FC<GridProps> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};
