export const isBrowser = (): boolean => typeof window !== 'undefined';

class UserService {
    public getUserName = (): string => {
        if (isBrowser() && window.localStorage.name) {
            const name = localStorage.getItem('name');

            return name ? name : '';
        }
        return '';
    };

    public getUserEmail = (): string => {
        if (isBrowser() && window.localStorage.email) {
            const email = localStorage.getItem('email');

            return email ? email : '';
        }
        return '';
    };

    public editUserName = (name: string): boolean => {
        window.localStorage.setItem('name', name);
        return true;
    };

    public editUserEmail = (email: string): boolean => {
        window.localStorage.setItem('email', email);
        return true;
    };
}

export default new UserService();
