// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { screens, spacing } from '@styles/theme';

export interface InnerProps {
    children: ReactNode;
}

const Container: React.FC<InnerProps> = ({ children, ...props }) => {
    return <div {...props}>{children}</div>;
};

export const Inner = styled(Container)<InnerProps>`
    position: relative;

    width: 100%;
    margin: 0 auto;

    @media (min-width: ${screens('md')}) {
        max-width: ${screens('md')};
        padding: ${spacing(12)} ${spacing(8)};
    }

    @media (min-width: ${screens('lg')}) {
        max-width: ${screens('lg')};
        padding: ${spacing(12)} ${spacing(8)};
    }

    @media (max-width: ${screens('md')}) {
        max-width: ${screens('md')};
        padding: ${spacing(12)} ${spacing(8)};
    }

    @media (max-width: ${screens('sm')}) {
        max-width: ${screens('sm')};
        padding: ${spacing(12)} ${spacing(8)};
    }
`;
