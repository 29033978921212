// Modules
import React from 'react';
import { GatsbyImageProps } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import Particles from 'react-particles-js';

// Styles
import styled from '@emotion/styled';
import { spacing } from '@styles/theme';
import { css } from '@emotion/core';

const config = {
    particles: {
        number: {
            value: 100,
            density: {
                enable: true,
                value_area: 800,
            },
        },
        color: {
            value: '#ffffff',
        },
        shape: {
            type: 'circle',
            stroke: {
                width: 0,
                color: '#000000',
            },
        },
        opacity: {
            value: 1,
            random: true,
            anim: {
                enable: true,
                speed: 1,
                opacity_min: 0,
                sync: false,
            },
        },
        size: {
            value: 3,
            random: true,
            anim: {
                enable: false,
                speed: 4,
                size_min: 0.3,
                sync: false,
            },
        },
        line_linked: {
            enable: false,
            distance: 150,
            color: '#ffffff',
            opacity: 0.1,
            width: 1,
        },
        move: {
            enable: true,
            speed: 1,
            direction: 'none',
            random: true,
            straight: false,
            out_mode: 'out',
        },
    },
    interactivity: {
        events: {
            onhover: {
                enable: false,
            },

            resize: true,
        },
    },
    retina_detect: true,
};

interface CardImageProps {
    image: {
        childImageSharp: {
            fluid: { aspectRatio: number; base64: string; sizes: string; src: string; srcSet: string };
        };
    };
    disabled?: boolean;
}

const Container = styled(BackgroundImage)<CardImageProps>`
    width: 100%;
    height: ${spacing(64)};

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    ${props => props.disabled && `filter: blur(3px) grayscale(100%);`}
`;

export const CardImage: React.FC<CardImageProps & GatsbyImageProps> = ({
    image: {
        childImageSharp: { fluid },
    },
    disabled,
}) => {
    return (
        <>
            <Container fluid={fluid} disabled={disabled}>
                <Particles
                    params={config}
                    css={css`
                        position: absolute;
                        z-index: 10;
                        height: 100%;
                    `}
                />
            </Container>
        </>
    );
};
