// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { screens, spacing } from '@styles/theme';

export interface OuterProps {
    children: ReactNode;
}

const Container: React.FC<OuterProps> = ({ children, ...props }) => {
    return <div {...props}>{children}</div>;
};

export const Outer = styled(Container)`
    width: 100%;

    padding: 0;

    @media (min-width: ${screens('sm')}) {
        padding: 0;
    }

    @media (min-width: ${screens('md')}) {
        padding: 0;
    }

    @media (min-width: ${screens('lg')}) {
        margin: 0;
        padding: 0;
    }
`;
