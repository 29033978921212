// Modules
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import styled from '@emotion/styled';

// Interfaces
import { IconProps } from '..';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<IconProps>`
    ${props => props.colored && `color: #61DAFB`};
`;

export const ReactIcon: React.FC<IconProps> = ({ colored, ...props }) => {
    return <StyledFontAwesomeIcon icon={['fab', 'react']} fixedWidth={true} colored={colored} {...props} />;
};
