// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { spacing } from '@styles/theme';

export interface CardBodyProps {
    children: ReactNode;
}

const Container = styled.div`
    padding: ${spacing(4)};
`;

export const CardBody: React.FC<CardBodyProps> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};
