// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { fontFamily, fontSize, fontWeight, letterSpacing, colors, borderRadius, spacing } from '@styles/theme';

export interface TypoProps {
    children: ReactNode;
    variant?: 'h1' | 'h2' | 'h3' | 'p' | 'menu' | 'label' | 'button';
    transform?: 'capitalize';
    [prop: string]: any;
}

const H1 = styled.h1`
    margin: 0;

    font-weight: ${fontWeight('medium')};
    font-size: ${fontSize('xxl')};
    font-family: ${fontFamily('sans')};
`;

const H2 = styled.h2`
    margin: 0;

    font-weight: ${fontWeight('medium')};
    font-size: ${fontSize('xl')};
    font-family: ${fontFamily('sans')};
`;

const H3 = styled.h3`
    margin: 0;

    font-weight: ${fontWeight('medium')};
    font-size: ${fontSize('lg')};
    font-family: ${fontFamily('sans')};
`;

const MenuHeading = styled.h1`
    margin: 0;

    font-weight: ${fontWeight('bold')};
    font-size: ${fontSize('menu')};
    font-family: ${fontFamily('sans')};
`;

const Label = styled.label`
    margin: 0;

    font-weight: ${fontWeight('medium')};
    font-size: ${fontSize('xs')};
    font-family: ${fontFamily('sans')};
`;

const Button = styled.button`
    width: 100%;
    margin: 0;

    padding: ${spacing(2)} ${spacing(4)};

    color: ${colors('gray', 700)};
    font-weight: ${fontWeight('normal')};
    font-size: ${fontSize('sm')};
    font-family: ${fontFamily('sans')};

    background-color: ${colors('white')};

    border: 1px ${colors('gray', 600)} solid;

    :hover {
        color: ${colors('secondary')};

        background-color: ${colors('white')};
        border: 1px ${colors('secondary')} solid;
        cursor: pointer;
    }

    border-radius: ${borderRadius('md')};
`;

const P = styled.p`
    margin: 0;

    font-weight: ${fontWeight('normal')};
    font-size: ${fontSize('md')};
    font-family: ${fontFamily('sans')};
`;

const T = styled.span`
    margin: 0;

    font-weight: ${fontWeight('normal')};
    font-size: ${fontSize('md')};
    font-family: ${fontFamily('sans')};
`;

export const Typo: React.FC<TypoProps> = ({ variant, transform, children, ...props }) => {
    let typo = <T {...props}>{children}</T>;

    if (variant === 'h1') {
        typo = <H1 {...props}>{children}</H1>;
    }

    if (variant === 'h2') {
        typo = <H2 {...props}>{children}</H2>;
    }

    if (variant === 'h3') {
        typo = <H3 {...props}>{children}</H3>;
    }

    if (variant === 'p') {
        typo = <P {...props}>{children}</P>;
    }

    if (variant === 'menu') {
        typo = <MenuHeading {...props}>{children}</MenuHeading>;
    }

    if (variant === 'label') {
        typo = <Label {...props}>{children}</Label>;
    }

    if (variant === 'button') {
        typo = <Button {...props}>{children}</Button>;
    }

    if (transform === 'capitalize') {
        console.log(typo.props);
    }

    return typo;
};
