// Modules
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import styled from '@emotion/styled';
import { colors } from '@styles/theme';

// Interfaces
import { IconProps } from '..';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<IconProps>`
    ${props => props.colored && `color:${colors('red', 500)}`};
`;

export const HeartIcon: React.FC<IconProps> = ({ colored, ...props }) => {
    return <StyledFontAwesomeIcon icon={['fad', 'heart']} fixedWidth={true} colored={colored} {...props} />;
};
