// Components
import React, { ReactNode } from 'react';

import { css } from '@emotion/core';
import { colors, spacing, fontSize } from '@styles/theme';
import { Icon, IconId } from '../icon';
import { Typo } from '..';

export interface CollapseProps {
    children: ReactNode;
    id: IconId;
    inline: boolean;
}

export const Title: React.FC<CollapseProps> = ({ children, id, inline, ...props }) => {
    return (
        <Typo
            {...props}
            variant="h3"
            css={css`
                margin-bottom: 1rem;
                line-height: 1.375;
                display: ${inline ? 'inline' : 'block'};
                font-size: 1.17em;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                font-weight: bold;
                svg {
                    font-size: ${inline ? fontSize('lg') : fontSize('xl')};
                    margin-right: ${spacing(2)};
                    margin-left: ${inline ? spacing(2) : 0};
                }
            `}
        >
            <Icon id={id}></Icon>
            {children}
        </Typo>
    );
};
