// Modules
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import styled from '@emotion/styled';

// Interfaces
import { IconProps } from '..';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<IconProps>`
    ${props => props.colored && `color: #F0DB4F`};
`;

export const JsIcon: React.FC<IconProps> = ({ colored, ...props }) => {
    return <StyledFontAwesomeIcon icon={['fab', 'js']} fixedWidth={true} colored={colored} {...props} />;
};
