// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { spacing } from '@styles/theme';

interface CardFooterProps {
    children: ReactNode;
}

const Container = styled.div`
    padding: ${spacing(4)};
    padding-top: 0;
`;

export const CardFooter: React.FC<CardFooterProps> = ({ children, ...props }) => {
    return <Container {...props}>{children}</Container>;
};
