// Modules
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import styled from '@emotion/styled';
import { colors } from '@styles/theme';

// Interfaces
import { IconProps } from '..';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<IconProps>`
    ${props => props.colored && `color:${colors('green', 400)}`};
`;

export const NodeJsIcon: React.FC<IconProps> = ({ colored, ...props }) => {
    return <StyledFontAwesomeIcon icon={['fab', 'node-js']} fixedWidth={true} colored={colored} {...props} />;
};
