// Modules
import React, { ReactNode } from 'react';

// Styles
import styled from '@emotion/styled';
import { fontSize, colors, lineHeight } from '@styles/theme';

// Components
import { Typo } from '@components/core/typo';
import css from '@emotion/css';

export interface CardTitleProps {
    children: ReactNode;
    disabled?: boolean;
}

const disabledStyle = css`
    color: ${colors('gray', 700)};
`;

const StyledTypo = styled(Typo)<CardTitleProps>`
    font-size: ${fontSize('md')};
    line-height: ${lineHeight('relaxed')};

    ${props => props.disabled && disabledStyle}
`;

export const CardTitle: React.FC<CardTitleProps> = ({ children, ...props }) => {
    return (
        <StyledTypo variant="h2" {...props}>
            {children}
        </StyledTypo>
    );
};
