import { css } from '@emotion/core';
import { colors, spacing, lineHeight } from './theme';

export const global = css`
    * {
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        background-color: ${colors('white')};
    }
    a {
        color: inherit;
        text-decoration: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }

    .mdx-content {
        max-width: 100%;
        * {
            user-select: auto;
        }

        color: ${colors('secondary')};

        h1 {
            margin-bottom: ${spacing(8)};

            line-height: ${lineHeight('relaxed')};
        }

        h2 {
            margin-bottom: ${spacing(4)};

            line-height: ${lineHeight('snug')};
        }

        h3 {
            margin-bottom: ${spacing(4)};

            line-height: ${lineHeight('snug')};
        }

        h4 {
            margin-bottom: ${spacing(4)};

            line-height: ${lineHeight('snug')};
        }

        h5 {
            margin-bottom: ${spacing(4)};

            line-height: ${lineHeight('snug')};
        }

        h6 {
            margin-bottom: ${spacing(4)};

            line-height: ${lineHeight('snug')};
        }

        p {
            margin-bottom: ${spacing(4)};

            line-height: ${lineHeight('loose')};
        }

        .gatsby-highlight {
            margin-bottom: ${spacing(6)};
        }

        blockquote {
            margin: 0;
            padding-left: ${spacing(8)};

            color: ${colors('gray', 700)};
            font-style: italic;

            border-left: 2px ${colors('primary')} solid;
        }

        ul {
            line-height: ${lineHeight('loose')};
        }

        a {
            color: ${colors('blue', 500)};
            :hover {
                color: ${colors('blue', 400)};
            }
        }

        .gatsby-resp-image-wrapper {
            margin: ${spacing(8)} auto;
        }
    }
`;
